@import './cart/cart.scss';

.errorPage {
  max-width: 1110px;
  margin: 0 auto;
  margin-top: 200px;
  margin-bottom: 300px;
}

input {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
}
