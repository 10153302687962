.App {
    text-align: center;
    font-family: Arial;
    font-family: 'Titillium Web', sans-serif;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.login-form-wrapper {
    margin-top: 200px;
    display: flex;
    justify-content: center;
}